import React from "react";
import ReactDOM from "react-dom";

if (window.__INITIAL_STATE_autosuggest_search_bar__) {
  const { brand } = window.__INITIAL_STATE_autosuggest_search_bar__;
  (async () => {
    const { default: App } = await import("./App");
    ReactDOM.hydrate(
      <App
        brand={brand as "default" | "littlewoods" | "very" | "veryireland"}
      />,
      document.getElementById("search-bar-root"),
    );
  })();
}
